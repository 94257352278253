"use client";
import { createSafeContext } from "@open/hoose/utils/create-safe-context";
import { useUserData } from "app/_data-providers/UserDataProvider";
import { NEXT_PUBLIC_HEMIDAL_URL } from "data/base-url";
import { produce } from "immer";
import { useSession } from "next-auth/react";
import React, { ReactNode, useCallback, useEffect, useReducer } from "react";
import { useMount } from "react-use";
import { Socket, io } from "socket.io-client";
import { useOrgData } from "./OrgDataProvider";

const socketUrl = NEXT_PUBLIC_HEMIDAL_URL?.replace("backend", "") ?? "" ?? "";

type SocketState = {
  state: "stale" | "connected" | "retrying" | "disconnected" | "error";
  reason: string | null;
  reconnectAttempts: number | null;
};

type SocketContextData = {
  __socket: Socket | null;
  state: SocketState;
};

type ActionType =
  | {
      type: "CONNECTED_AND_JOINED";
    }
  | {
      type: "RECONNECT_ATTEMPT";
      payload: number;
    }
  | {
      type: "CONNECTED";
    }
  | {
      type: "DISCONNECTED";
      payload: string;
    };

const HEARTBEAT_INTERVAL = 1000 * 40; // 40 seconds

function socketReducer(state: SocketState, action: ActionType) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "RECONNECT_ATTEMPT":
        draft.state = "retrying";
        draft.reconnectAttempts = action.payload;
        break;
      case "CONNECTED":
        draft.state = "connected";
        break;
      case "DISCONNECTED":
        draft.state = "disconnected";
        draft.reason = action.payload;
        break;
    }
  });
}

const [SocketSafeProvider, useSocket] =
  createSafeContext<SocketContextData>("");

function SocketProvider({ children }: { children: ReactNode }) {
  const { id } = useUserData();
  const { id: orgId } = useOrgData();
  const [state, dispatch] = useReducer(socketReducer, {
    state: "stale",
    reason: null,
    reconnectAttempts: null,
  });
  const [socket, setSocket] = React.useState<Socket | null>(null);

  useMount(() => {
    if (id && orgId) {
      const sock = io(socketUrl, {
        transports: ["websocket"],
        closeOnBeforeunload: true,
        query: {
          client: "dashboard",
          userId: id,
          orgId,
        },
      });
      setSocket(sock);
      return () => {
        sock.close();
      };
    }
  });

  const handleConnect = useCallback(() => {
    if (!socket) return;
    socket.emit("join:dashboard", { orgId, userId: id });
    dispatch({ type: "CONNECTED" });

    const timer = setInterval(() => {
      socket.emit("heartbeat", {
        client: "dashboard",
        userId: id,
        orgId,
      });
    }, HEARTBEAT_INTERVAL);

    return () => {
      clearInterval(timer);
    };
  }, [id, orgId, socket]);

  const handleDisconnect = useCallback((reason: string) => {
    dispatch({ type: "DISCONNECTED", payload: reason });
  }, []);

  const handleReconnectAttempt = useCallback((attempt: number) => {
    dispatch({ type: "RECONNECT_ATTEMPT", payload: attempt });
  }, []);

  useEffect(() => {
    if (!socket) return;
    socket.on(
      "joined:dashboard",
      (data: {
        orgId: string;
        userId: string;
      }) => {
        if (data.userId === id.toString()) {
          dispatch({ type: "CONNECTED_AND_JOINED" });
        }
      },
    );

    return () => {
      socket.off("joined:dashboard");
    };
  }, [id, socket]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("reconnect_attempt", handleReconnectAttempt);
    socket.on("joined:dashboard", (data: { orgId: string; userId: string }) => {
      if (data.userId === id.toString()) {
        dispatch({ type: "CONNECTED_AND_JOINED" });
      }
    });

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("reconnect_attempt", handleReconnectAttempt);
      socket.off("joined:dashboard");
    };
  }, [socket, handleConnect, handleDisconnect, handleReconnectAttempt, id]);

  // const heartbeat = useCallback(debounce(() => {
  //   if (socket) {
  //     socket.emit("heartbeat", {
  //       client: "dashboard",
  //       userId: id,
  //       orgId,
  //     });
  //   }
  // }, 1000), [socket, id, orgId]);

  // useEffect(() => {
  //   let timer: NodeJS.Timeout;

  //   if (socket) {
  //     heartbeat();
  //     timer = setTimeout(heartbeat, HEARTBEAT_INTERVAL);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //   };

  // }, [socket, heartbeat]);

  return (
    <SocketSafeProvider value={{ __socket: socket, state }}>
      {children}
    </SocketSafeProvider>
  );
}

function useSocketEvent(event: string, callback: (...args: any[]) => void) {
  const { __socket } = useSocket();
  useEffect(() => {
    if (__socket) {
      __socket.on(event, callback);
    }
    return () => {
      if (__socket) {
        __socket.off(event, callback);
      }
    };
  }, [__socket, event, callback]);
}

export { useSocket, SocketProvider, useSocketEvent };
