"use client";
import { createSafeContext } from "@open/hoose/utils/create-safe-context";
import { components } from "@open/sdk";
import React from "react";
import { EmptyBlock } from "../../components/domain/EmptyBlock";

type User = components["schemas"]["UserDto"];

const [UserdataSafeProvider, useUserData] = createSafeContext<User>("");

function UserDataProvider({
  children,
  data,
}: {
  children: React.ReactNode;
  data: { user: User };
}) {
  if (!data) {
    return (
      <div className="flex-center size-full">
        <EmptyBlock>
          <p>
            We couldn't load the application. we have been notified of this
            error and will fix it as soon as possible.
          </p>
        </EmptyBlock>
      </div>
    );
  }
  return (
    <UserdataSafeProvider value={data.user}>{children}</UserdataSafeProvider>
  );
}
export { useUserData, UserDataProvider };
