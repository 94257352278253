import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from "@open/hoose/ui";
import { cn } from "lib/utils";
import React from "react";
import { ReactNode } from "react";

interface EmptyStateProps {
  title: string;
  description: string;
  action?: ReactNode;
  className?: string;
  icon?: ReactNode;
}

export function EmptyState({
  title,
  description,
  action,
  className,
  icon,
}: EmptyStateProps) {
  return (
    <Card className={cn("flex flex-col items-center gap-4", className)}>
      <CardContent className="flex flex-col items-center gap-4">
        {icon ? (
          icon
        ) : (
          <CardTitle className="text-4xl p-4">¯\_(ツ)_/¯</CardTitle>
        )}
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardContent>
      {action && <CardFooter className="justify-center">{action}</CardFooter>}
    </Card>
  );
}
